import React, { useEffect } from "react";
import {
  initMercadoPago,
  CardPayment as MPCardPayment,
} from "@mercadopago/sdk-react";

import { Totals } from "../../models/checkout_model";
import axios from "../../middleware/axios";
import { UserType } from "../../models/user_model";
initMercadoPago(process.env.REACT_APP_MP_KEY!);

////test cards
// Mastercard
// 5474 9254 3267 0366
// 123
// 11/25
// Visa
// 4075 5957 1648 3764
// 123
// 11/25
// American Express
// 3743 781877 55283
// 1234
// 11/25

type PaymentFormProps = {
  totals: Totals;
  user: UserType;
  onSuccess: any;
  setError_message: any;
  setOrderObject: any;
};
const PaymentForm: React.FC<PaymentFormProps> = ({
  totals,
  user,
  onSuccess,
  setError_message,
  setOrderObject,
}) => (
  <CardPayment
    setError_message={setError_message}
    totals={totals}
    user={user}
    onSuccess={onSuccess}
    setOrderObject={setOrderObject}
  />
);

type CardPaymentProps = {
  totals: Totals;
  user: UserType;
  onSuccess: any;
  setError_message: any;
  setOrderObject: any;
};
const CardPayment: React.FC<CardPaymentProps> = ({
  totals,
  user,
  onSuccess,
  setError_message,
  setOrderObject,
}) => {
  useEffect(() => {
    return () => {
      // Clean up the controller when the component unmounts
      ///@ts-ignore next-line
      window.cardPaymentBrickController.unmount();
    };
  }, []);

  const onSubmit = async (param: any): Promise<void> => {
    //@ts-ignore
    // callback called when clicking the submit data button
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/scriptsPaymentsMpMakePayment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: { ...param, user_id: user.id },
        });
        if (response.data.error) {
          setError_message(response.data.message);
          return reject(void 0);
        }
        const order_obj = setOrderObject();
        order_obj.mp_payment = {
          authorization_code: response.data.authorization_code,
          card: response.data.card.last_four_digits,
        };
        order_obj.meses = response.data.installments;
        order_obj.ipgTransactionId = response.data.payment_method_id;
        onSuccess(false, order_obj);
        return resolve(void 0);
      } catch (error) {
        console.log("orderResume.tsx:307 | error ", error);
        reject(void 0);
      }
    });
  };
  const onError = async (error: any) => {
    // callback called for all Brick error cases
    console.log(error);
  };
  const onReady = async () => {
    console.log("orderResume.tsx:344 | Brick is ready");
  };

  return (
    <div className="row ">
      <div className="col-md-12">
        <MPCardPayment
          initialization={{
            amount: totals.total,
            payer: {
              email: user.email!,
            },
          }}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
          locale="es-MX"
        />
      </div>
    </div>
  );
};

export default PaymentForm;
